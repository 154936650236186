<template>
    <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container">
            <h2>Общественная экспертиза</h2>
            <tricolor-line class="tricolor_line"/>
            <div class="panel__tabs">
              <p v-for="(tab, index) in tabs" :class="{'active' : isActiveTab === index}" :key="`tab${index}`" @click="setTabNews(tab.route)">{{ tab.name }}</p>
            </div>

          <header class="tab-header">
            <h3>Последние новости</h3>

            <router-link class="section_wrapper__link" to="/news?tab=News">
              Посмотреть все<span></span>
            </router-link>
          </header>

          <div class="block_photo_card">
            <Carousel class="block_card" :count="2" key="card">
              <dynamic-news-card v-if="getPublicNews.length > 0"
                                 :is-list-view="false"
                                 :content="getPublicNews[0]"
                                 :key="`newsCard${index}`"
                                 class="card_carusel"/>

              <dynamic-news-card v-if="getPublicNews.length > 1"
                                 :is-list-view="false"
                                 :content="getPublicNews[1]"
                                 :key="`newsCard${index}`"
                                 class="card_carusel"/>
            </Carousel>
            <div class="news__important-container">
              <activities-photo-news-dynamic class="card_important" :card="getPublicNews[2]" v-if="getPublicNews.length > 2"/>
              <activities-photo-news-dynamic class="card_important" :card="getPublicNews[3]" v-if="getPublicNews.length > 3"/>
              <activities-photo-news-dynamic class="card_important" :card="getPublicNews[4]" v-if="getPublicNews.length > 4"/>
            </div>
          </div>
          <div class="mobel_card">
            <dynamic-news-card v-if="getPublicNews.length > 0"
                                 :is-list-view="false"
                                 :content="getPublicNews[0]"
                                 :key="`newsCard${index}`"
                                 class="card_carusel"/>

              <dynamic-news-card v-if="getPublicNews.length > 1"
                                 :is-list-view="false"
                                 :content="getPublicNews[1]"
                                 :key="`newsCard${index}`"
                                 class="card_carusel"/>
          </div>

            <h3>Законопроекты для обсуждения</h3>
            <div class="block_surveys">
                <bills-card v-for="(survey, key) in surveys.templated" :key="`link${key}`" :card="survey" class="containet_block"/>
            </div>

            <h3>План экспертной деятельности</h3>
            <documents-info class="documents-info" v-for="item in document" :key="`documents${item.title}`" :content="item" />

        </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import BillsCard from '@/components/activitiesSurveys/BillsCard'
import Carousel from '@/components/Carousel'
import DocumentsInfo from '@/components/DocumentsInfo'

import ActivitiesPhotoNewsDynamic from '@/components/activitiesSurveys/ActivitiesPhotoNewsDynamic'
import formatDate from '@/mixins/formatDate'
import DynamicNewsCard from '@/components/newsList/DynamicNewsCard'

export default {
  name: 'PublicExamination',
  mixins: [formatDate],
  data () {
    return {
      isActiveTab: 0,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '/'
        },
        {
          title: 'Общественная экспертиза'
        }
      ],
      tabs: [
        { name: 'Новости', route: '/public-examination' },
        { name: 'Законопроекты для обсуждения', route: '/public-examination-bills' },
        { name: 'Экспертные заключения', route: '/public-examination-opinion' }
      ],
      document: [],
      news: {
        title: 'Последние новости',
        url_link: '/',
        url_name: 'Посмотреть все',
        templated: [
          {
            date: '11.08.2020',
            title: 'НКО в Кургане и форум «Сообщество»: два месяца спустя',
            subtitle: 'НКО в Кургане и форум «Сообщество»: два месяца спустя',
            photo: 'ativity1.png'
          },
          {
            date: '11.08.2020',
            title: 'Форумы «Сообщество» в Сочи и Барнауле перенесены на более поздний срок',
            subtitle: 'Решение принято в связи с неблагоприятной эпидемиологической обстановкой',
            photo: 'ativity2.png'
          },
          {
            date: '11.08.2020',
            title: '«Давайте не будем стесняться говорить бизнесу спасибо!» — Вадим Ковалев',
            subtitle: '«Давайте не будем стесняться говорить бизнесу спасибо!» — Вадим Ковалев',
            photo: 'slider1.png'
          },
          {
            date: '11.08.2020',
            title: 'Всероссийский молодежный форум «Восток»: объединить молодых лидеров со всей страны',
            subtitle: 'Всероссийский молодежный форум «Восток»: объединить молодых лидеров со всей страны',
            photo: 'news14.png'
          },
          {
            date: '11.08.2020',
            title: 'Экоферма в Алтайском крае под угрозой из-за перекрытой дачниками дороги',
            subtitle: 'НКО в Кургане и форум «Сообщество»: два месяца спустя',
            photo: 'news2.png'
          },
          {
            date: '11.08.2020',
            title: 'НКО в Кургане и форум «Сообщество»: два месяца спустя',
            subtitle: 'НКО в Кургане и форум «Сообщество»: два месяца спустя',
            photo: 'ativity1.png'
          },
          {
            date: '11.08.2020',
            title: 'НКО в Кургане и форум «Сообщество»: два месяца спустя',
            subtitle: 'НКО в Кургане и форум «Сообщество»: два месяца спустя',
            photo: 'ativity1.png'
          }
        ]
      },
      surveys: {
        title: 'Законопроекты для обсуждения',
        url_link: '/',
        url_name: 'Посмотреть все',
        templated: [
          {
            date: '11.08.2020',
            title: 'Законопроект о противодействии коррупции',
            subtitle: 'Новый законопроект относится к противодействию коррупции на территории РФ и ',
            photo: 'hammer.svg',
            link: '/adopted_bill'
          }
        ]
      }
    }
  },
  beforeCreate () {
    this.$store.dispatch('setBillDraft')
    this.$store.commit('setFilterSystemTagList', 'public_examination')
    this.$store.dispatch('getPublicNews')
  },
  computed: {
    getPublicNews () {
      return this.$store.getters.getPublicNews
    },
    getBillDraft () {
      return this.$store.getters.getBillDraft
    },
    getListView () {
      return this.$store.getters.getListView
    }
  },
  watch: {
    getBillDraft () {
      this.setBillDraft()
    }
  },
  methods: {
    setTabNews (route) {
      this.$router.push({ path: `${route}` })
    },
    async setPlanDocuments () {
      const formdata = new FormData()
      formdata.append('systemTagList', JSON.stringify(['/activity_plan']))
      let res = await fetch(`${this.$store.getters.getUrlApi}api/document/list`, {
        method: 'POST',
        body: formdata
      })
      res = await res.json()
      this.document = await res.data.documents
    },
    setBillDraft () {
      this.surveys.templated.length = 0
      this.$store.getters.getBillDraft.forEach(item => {
        this.surveys.templated.push({
          ...item,
          link: `/adopted_bill/${item.id}`,
          photo: 'hammer.svg'
        })
      })
    }
  },
  mounted () {
    this.setPlanDocuments()
    document.title = 'Общественная экспертиза'
  },
  components: {
    DynamicNewsCard,
    BreadCrumb,
    TricolorLine,
    BillsCard,
    ActivitiesPhotoNewsDynamic,
    DocumentsInfo,
    Carousel
  }
}
</script>
<style lang="scss" scoped>

  .tab-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    a {
      color: #246CB7;
      mix-blend-mode: normal;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-size: .8rem;

      span {
        background: url(/img/arrow.4d767eb5.svg);
        background-size: cover;
        background-repeat: no-repeat;
        display: inline-block;
        transform: rotate(-90deg);
        width: 1rem;
        height: 1rem;
        margin-top: .1rem;
      }
    }
  }

.white-container{
    margin: 0 3.8rem;
    padding: 2.5rem 2.94rem;
}

h2{
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 2rem;
}
h3{
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
  margin-bottom: 1.375rem;
  color: #1F3245;
}
.tricolor_line{
  margin-bottom: 2rem;
}
  .panel__tabs {
    display: flex;
    padding-bottom: 1.375rem;
    border-bottom: .0625rem solid #D5D6D9;
    margin-bottom: 2rem;
    color: #003E78;

    p, .active {
      font-size: 0.8125rem;
      margin-right: 1.25rem;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      opacity: 0.6;

      cursor: pointer;
    }

    .active {
      font-weight: 600;
      color: #246CB7;
      opacity: 1;
    }

    .active::before {
      content: '';
      height: 100%;
      width: 100%;
      bottom: -1.45rem;
      position: absolute;
      border-bottom: 0.125rem solid #246CB7;
    }

  }
.block_photo_card{
    display: flex;
    margin-bottom: 1.88rem;
  justify-content: space-between;
}

.news__important-container{
    width: 26.625rem;
    flex-direction: column;
    flex-wrap: nowrap;
}
.card_important{
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #eee;
}
.news__important-container  .card_important:nth-child(3){
    border: 0;
}

.documents-info {
  margin-bottom: 2rem;
}

.block_card{
    display: flex;
    flex-wrap: wrap;

  *:first-child {
    margin-right: 1rem;
  }
    // justify-content: space-between;
}
.card_title-link:nth-child(1){
    margin-right: 2.1rem
}
.contain{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.06rem;
    .containet_block{
        margin: 0 1.06rem 1.87rem 1.06rem;
        height: 18.9375rem;
        width: 26.18rem;//calc(33.333% - 2.125rem);
    }
    // justify-content: space-between;
}

.card_containet__tabs{
    margin-top: 1.44rem;
}
.card_line{
    background: #D5D6D9;
    border-radius: .125rem;
    height: .125rem;
    width: 100%;
    margin: .94rem 0 1.375rem 0;
}

.info-news {
//   margin-bottom: 1.88rem;
  width: 26.56rem;
  height: 23.44rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.38rem;
  &:nth-child(2){ margin: 0 2.19rem; }

  &__type {
    width: 100%;
    height: 14.38rem;

    margin-bottom: 1.25rem;
    border-radius: .5rem;
  }
  &__date{
    font-size: .875rem;
    line-height: 1.38rem;
    color: #5E686C;
    opacity: 0.8;
    margin-bottom: .625rem;
  }

  &__title{
    font-weight: 600;
    font-size: .875rem;
    line-height: 1.38rem;
    color: #1F3245;
    margin-bottom: .312rem;
  }

  &__text{
    font-size: .875rem;
    line-height: 1.38rem;
    color: #171717;
    opacity: 0.8;
  }

}
.button_block {
    display: flex;
}

.block_surveys {
  display: flex;
  flex-wrap: wrap;
  // overflow: auto;
  justify-content: space-between;
  margin-bottom: 4rem;

  .carousel {
    overflow: auto;
    width: 100%;
    .info-banner{
      margin-right: 1.385rem;
    }
  }
}
.mobel_card{
  display: none;
}
@media screen and (max-width: 768px){
  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
  .info-news {
    width: 45%;
 }
  .news__important-container {
    display: none;
  }
  .contain .containet_block {
    width: 45%;
  }
  .contain .containet_block:nth-child(n+3) {
    display: none;
  }
  .block_card{
      width: 100%;
      .card_title-link[data-v-1c521664]:nth-child(1) {
          margin-right: 0;
      }
  }
  .block_surveys {
    margin: 2rem 0;
    .containet_block{
      width: 45%;
    }

    .carousel {
      .info-banner{
        margin-right: 0;
        width: 21rem;
      }
    }
  }
  .carousel {
      display: flex;
      overflow: auto;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
  }
  .info-news:nth-child(2) {
      margin: 0 0 0 2.19rem;
  }
}
@media screen and (max-width: 420px){
    .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .contain .containet_block {
      width: 100%;
    }
    .info-news {
      width: 22.9rem;
      scroll-snap-align: start;
      flex-shrink: 0;
    }
    .block_photo_card{
      display: none;
    }
    .block_card{
      display: inline-flex;
      justify-content: space-between;
      overflow: auto;
      width: 100%;
        .card_title-link:not(:first-child) {
          display: none;
        }
    }
    .mobel_card{
      display: flex;
      flex-direction: column;
    }
    .card_carusel{
      margin-bottom: 1.88rem;
    }

    .block_surveys {
      margin: 2rem 0;
      .containet_block{
        width: 100%;
      }

      .carousel {
        .info-banner{
          width: 100%;
        }
      }
    }

    .panel__tabs {
      overflow-x: scroll;
      flex-wrap: nowrap;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      p{
        flex: 0 0 auto;
      }
      .active::before {
        content: '';
        height: 100%;
        width: 100%;
        bottom: -1.38rem;
      }
    }
}

</style>
